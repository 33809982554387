import { useEffect } from 'react';
import { useNotifications } from '../../notifications/hooks/useNotifications';
import { waiting_room_model } from '../../models/waiting_room_model';
import { useApp } from 'xAppLib/Hooks';
const NOTIFICATION_KEY = 'escript_on_hold';

export const useEScriptsOnHold = () => {
    const { setDynamicNotification, removeDynamicNotification } = useNotifications();
    const app = useApp();

    useEffect(() => {
        if (!app?.acl?.can_escript_review || app?.settings?.icosm) return;

        return waiting_room_model.watch_escript_review(recs => {
            const scripts = Object.values(recs || {});
            const scriptsCountStuck = scripts.filter(
                s => ['stuck_escript', 'stuck_escript_noti'].includes(s.status)
            ).length;

            const text = `${scriptsCountStuck} eScripts${scriptsCountStuck > 1 ? 's' : ''} on hold`

            if (scriptsCountStuck > 0) {
                setDynamicNotification(NOTIFICATION_KEY, {
                    type: NOTIFICATION_KEY,
                    title: text,
                    icon: 'qrcode',
                    link: '/scripts/list/escript_review',
                    overrides: {
                        banner: true,
                        banner_variant: 'warning',
                    },
                });
            } else {
                removeDynamicNotification(NOTIFICATION_KEY);
            }
        });
    }, [app?.acl?.can_escript_review || app?.settings?.icosm]);
}

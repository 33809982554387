import React , {useMemo} from 'react';
import menu_model from "models/menu_model";
import { DebugExperiments } from "../../../xAppLib/UIelems/DebugExperiments";
import { SiteVariationSelector } from "../../../xAppLib/UIelems/SiteVariationSelector";
import DeviceOrgSelector from '../../icosm/DeviceOrgSelector';
import { Icon } from 'semantic-ui-react';
import { cls } from '../../NUI/utils';
import { AppleAppStoreButton, GooglePlayStoreButton } from "./AppStoreButtons";
import {conditions as conditionItems} from  './serviceAndConditionsMenu/ConditionsMenu'
import { BuildInfo } from "@/views/UIelems/BuildInfo";
import {Link} from "react-router-dom";


const MAX_ITEMS = 15;
const INSTANTSCRIPTS_MENU_ITEMS = [
    {title : 'News', href : '/blog'},
    {title : 'In the media', href : "/media_release"},
    {title : "Pricing", href : "/pricing"},
    {title : "Our Story", href : "/about-us"},
    {title : "Privacy", onClick : () => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt", txt:'Privacy', pn:'privacy-policy'})},
    {title : "Customer T&Cs", onClick : () => app.trigger(app.events.SHOW_POPUP, {
            pt: "cx_txt",
            txt: "Customer Terms and Conditions",
            pn: "customer-terms-and-conditions",
        })},
    {title : "Platform T&Cs",  onClick : () => app.trigger(app.events.SHOW_POPUP, {
            pt: "cx_txt",
            txt: "Platform Terms and Conditions",
            pn: "platform-terms-and-conditions",
        })}
]

const PROMOTION_MENU_ITEMS = [
    {title : "OnePass", href : "/onepass"},
    {title : "Refer a friend", href : "/refer-a-friend"}
]

const HELP_MENU_ITEMS = [
    {title : "Help center", href : "https://help.instantscripts.com.au/en/"},
    {title : "Contact us", href : "/contact-us"}
]

const WORK_WITH_US_MENU_ITEMS = [
    {title : "Pharmacies", href : "/pharmacy-registration/"}
]

// const MOBILE_MENU_ITEMS = [
//     {
//         title: "Language (English)",
//         href: "",
//     },
//     {
//         title: "Accessibility options",
//         href: "",
//     },
// ]
const apps = <div className="flex justify-center gap-3">
                <AppleAppStoreButton/>
                <GooglePlayStoreButton/>
            </div>
    
const INSTANT_SCRIPT_LOGO = <img src="/images/logos/instant-scripts-logo-IS2-white.svg" alt="InstantScripts" className="logo w-[132px] h-initial max-w-fit"/>

export function FooterDownload() {
    return  <div className={cls("text-center bg-is2-blue text-white")}>
                <div className="flex flex-col px-6 py-24 lg:py-[120px]">
                    <h2 className="mb-0">Download the app</h2>
                    <p className="my-9 text-base">For a better experience and additional features we recommend downloading our app.</p>
                    <div className="flex justify-center gap-3">
                        {apps}
                    </div>
                </div>
            </div>
}

const useFooterLists = () => {
 
    const instantScriptLinks = useMemo(() => {
        return {title : INSTANT_SCRIPT_LOGO, items : INSTANTSCRIPTS_MENU_ITEMS}
    },[])

    const services = useMemo(() => {
        const serviceMenu = menu_model.get_servicesmenu()
        return {title : 'Services', items : serviceMenu.flatMap(({items}) => items).filter((el) => el?.footerOrder).sort((a, b) => a.footerOrder - b.footerOrder)}
    }, [])

    const conditionsMenu = useMemo(() => {
        return {title : 'Top Conditions', items : conditionItems.common.map(({k, n, footerOrder}) => ({href : `/c/pharm/${k}`, title : n, footerOrder})).sort((a, b) => a.footerOrder - b.footerOrder)}
    }, [])

    const promotions = useMemo(() => {
        return {title : 'Promotions', items : PROMOTION_MENU_ITEMS}
    }, [])

    const workWithUs = useMemo(() => {
        return {title : 'Work With Us', items : WORK_WITH_US_MENU_ITEMS}
    },[])

  

    const help = useMemo(() => {
        return {title : 'Help', items : HELP_MENU_ITEMS}
    },[])

   


    return {services, workWithUs, promotions, conditionsMenu, instantScriptLinks, help}
}

const FooterLinkItem = ({href, title, target, onClick}) => {
    const isExternalLink =  href && (href.startsWith('http'))
    const className = "no-underline text-blue-300 text-sm cursor-pointer font-thin hover:text-white transition duration-300"
    return <div className="mb-1">
        {isExternalLink
            ? <a
                className={className}
                key={title}
                onClick={onClick}
                href={onClick ? null : href}
                target={target ?? "_blank"}
                >
                    {title}
            </a>
            : <Link
                className={className}
                onClick={onClick}
                to={onClick ? null : href}
                >
                    {title}
            </Link>
        }
    </div>
}


    
const FooterLinkList = ({footerListData}) => 
    <div className='text-left mb-8 flex-col text-nowrap'>
        <p className="text-blue-100 font-medium mb-2">{footerListData.title}</p>
        {footerListData.items.map((listItem, index) => index < MAX_ITEMS ? <FooterLinkItem {...listItem}/> : null)}
    </div>

export default function FooterMain({bg: _bg}) {

    const {services,  workWithUs, promotions, conditionsMenu, instantScriptLinks, help} = useFooterLists();

	return (<div className='text-white bg-is2-blue-900'>
                    <div className="bg-is2-blue-850 align-middle">
                        <div className={'mx-auto max-w-[1124px] px-6 z-10 hidden pt-[60px] pb-[44px] md:flex md:gap-[4em] lg:gap-[4em] items-between justify-between '}>
                            <div >
                                <FooterLinkList footerListData={instantScriptLinks}/>
                            </div>
                            <div>
                                <FooterLinkList footerListData={services}/>
                            </div>
                            <div>
                                <FooterLinkList footerListData={conditionsMenu}/>
                            </div>
                            <div>
                                <FooterLinkList footerListData={promotions}/>
                                <FooterLinkList footerListData={help}/>
                                <FooterLinkList footerListData={workWithUs}/>
                            </div>
                        </div>
                    </div>
                    
                        {(app.dev_env || app.test_env ) && <div className='md:hidden flex justify-center gap-[2em] pb-8 pt-11 bg-is2-blue-850'>
                        {/*<Language />*/}
                        {/*<AccessibilityOptions />*/}
                        </div>}

                <div className='flex flex-col md:flex-row bg-is2-blue-850 md:bg-is2-blue-900 w-100/100 min-h-[73px]  px-6 md:px-[2em] lg:px-[12em] items-center justify-between  text-xs text-white md:text-blue-100 font-thin'>
                    <div className='flex md:hidden justify-center pb-[24px]'>
                        {INSTANT_SCRIPT_LOGO}
                    </div>
                    <div className='flex flex-col md:flex-row justify-center items-center  my-2 md:my-0'>
                        <img src="https://storage.googleapis.com/instant-med-public/v2/Wesfarmers_logo_white.svg" alt="Wesfarmers" className="logo mb-2 h-[30px] mx-auto"/>
                        <p className='ml-0 md:ml-4 text-xs'>A Wesfarmers company</p>
                    </div>
                    <div className='flex items-center my-2 md:my-0'>
                        <p className="uppercase font-thin text-center text-xs">© InstantScripts  <span className="ml-4">ABN 28 008 984 049</span></p>
                    </div>
                </div>
                <div className="text-sm bg-is2-blue-600 p-4">
                    <DeviceOrgSelector />
                    {app.runtime.org?.oid!='app'&&<i>{app.runtime.org?.name}<br/></i> || null}
                    <BuildInfo/>
                    <Icon name="refresh" onClick={_=>window.location.reload(true)} className="cursor-pointer !ml-2" />
                    <DebugExperiments />
                    <SiteVariationSelector />
                </div>
            </div>)
}



// TODO - build language and accessibility option features
// These are hidden in production
const Language = () => {
    return <div><p className='text-xs'>Language (English)</p></div>
}

const AccessibilityOptions = () => {
    return <div><p className='text-xs'>Accessibility options</p></div>
}
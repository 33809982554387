const default_icon = Object.freeze({
    name: 'bell',
    color: 'text-grey-600'
});

const getNotificationIcon = (notification) => {
    const result = { ...default_icon };

    if (notification.icon) {
        result.name = notification.icon;
    }

    if (notification.icon_color) {
        result.color = notification.icon_color;
    }

    return result;
};

export default getNotificationIcon; 
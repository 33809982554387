import { useEffect } from 'react';
import { useNotifications } from '../../notifications/hooks/useNotifications';
import { waiting_room_model } from '../../models/waiting_room_model';
import { useApp } from 'xAppLib/Hooks';
const NOTIFICATION_KEY = 'urgent_follow_up';

export const useUrgentFollowUp = () => {
    const { setDynamicNotification, removeDynamicNotification } = useNotifications();
    const app = useApp();

    useEffect(() => {
        if (!app.acl.can_urgent_care) return;
        return waiting_room_model.watch_urgent_fu(recs => {
            const count = Object.values(recs || {}).filter(doc_filter).length;
            const text = `${count} patient${count > 1 ? 's' : ''} requiring urgent care`

            if (count > 0) {
                setDynamicNotification(NOTIFICATION_KEY, {
                    type: NOTIFICATION_KEY,
                    title: text,
                    icon: 'heartbeat',
                    link: '/scripts/list/urgent',
                    overrides: {
                        banner: true,
                        banner_variant: 'negative',
                        persistent: true,
                    },
                });
            } else {
                removeDynamicNotification(NOTIFICATION_KEY);
            }
        });
    }, [app.acl.can_urgent_care]);
}

function doc_filter(rec) {
	if (app.acl.is_supp || app.acl.is_admin) return true;
	if (!app.acl.can_urgent_care) return false;
	if (rec.doc?.doc_id && rec.doc.doc_id === app.user.claims.doc_id) return true;

	return !rec.doc?.doc_id;
}
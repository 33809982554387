import React, { useState, useEffect, useMemo } from "react";
import {
	Button,
	Container,
	Loader
} from "semantic-ui-react";
import { Link, useSearchParams } from "react-router-dom";
import { useProfile, useUser } from "xAppLib/Hooks";
import { cls } from 'views/NUI/utils';
import user_model from "../../models/user_model";
import environment from "../../conf/environment";
import InfoHomeCard from "../UIelems/v2/InfoHomeCards/InfoHomeCard";
import SingleInfoCard from "../UIelems/v2/InfoHomeCards/SingleInfoCard";
import { FooterDownload } from "../UIelems/v2/Footer";
import { SpeakToADoctorCopy, PrescriptionsCopy, MedicalCerfiticateCopy, PathologyCopy, SpecialistCopy } from "../UIelems/v2/stepsTabs/StepsTabsContent";
import StepsTabs from "../UIelems/v2/stepsTabs/StepsTabs";
import ConditionCardCarousel from "../UIelems/v2/conditionsCards/ConditionCardCarousel";
import ConditionCardGrid from "../UIelems/v2/conditionsCards/ConditionCardGrid";
import { HeaderHome } from "../UIelems/v2/Header/HeaderHome";
import FaqSection from "../UIelems/v2/FaqSection";
import { discount as refer_discount } from "./Refer";
import { TextImageBox } from "../UIelems/v2/TextImageBox";
import { HealthMonitor } from "../UIelems/v2/HealthMonitor";
import SIcon from "../NUI/StreamlineIcons/SIcon";
import Welcome from "../UIelems/v2/Header/Welcome";
import ActivePrescriptionsDashboard from "../../components/medications/ActivePrescriptionsDashboard";
import { LastOrder } from "../UIelems/v2/LastOrder";
import AccountOnboarding from "../../xAppLib/UIelems/AccountOnboarding";
import MFA from "../../xAppLib/UIelems/MFA";
import { ActiveConsult } from "../UIelems/v2/Header/ActiveConsult";
import NotificationBannersApp from "../../notifications/view/app/NotificationBannersApp";
import CommonAlerts from "../../xAppLib/UIelems/CommonAlerts";
import { useBreakpoint } from "@/views/ordering/hooks/useBreakpoint";

const img_path = environment.storage.instantMedPublic.is2;

const articles = [
	{
		id: 'refer',
		title: "Refer a friend",
		desc: <>Refer a friend to InstantScripts and you will both receive ${refer_discount} off your next order.</>,
		img: "refer-a-friend-home.jpg",
		link: "/refer-a-friend",
		secondaryBtn: "Maybe later",
		primaryBtn: <>Get ${refer_discount} off</>
	},
	{
		id: 'onepass',
		title: "Activate your OnePass membership",
		desc: "Get free standard delivery on eligible prescription medication in Aus and 5% off eligible InstantScripts services.",
		content: <div className="bg-op-purple flex items-center justify-center w-full h-[200px] rounded-[28px]">
			<img src="https://storage.googleapis.com/instant-med-public/OnePass/onepass_purple_bg.svg" />
		</div>,
		link: "/onepass",
		primaryBtn: "Activate now",
		secondaryBtn: "Maybe later"
	},
	{
		id: 'consults',
		title: `General telehealth consultations now $49`,
		desc: "We’ve dropped the price of telehealth consultations to make it even more convenient for you to get the healthcare you need.",
		img: "consults-home.jpg",
		link: "/c/pharm/DocCons",
		primaryBtn: "Request a consultation",
	},
	{
		id: 'certs',
		title: `Our online script service just got better!`,
		desc: "We can help ensure you don't go without your medication. We've updated our pricing and all online script requests are now just $19.",
		img: "medcert-home.jpg",
		link: "/m/pharm/online/prescription",
		primaryBtn: "Request a script"
	},
	{
		id: 'delivery',
		title: "We offer same day delivery!",
		desc: "We’ve partnered with Evermed to offer same day delivery so you don’t have to leave your house to get your medication. Small fee applies. Subject to availability in your area.",
		img: "delivery-home.jpg",
		link: "/c/pharm/DocCons",
		primaryBtn: "Request a consultation"
	},
	{
		id: 'patho',
		title: "Get a pathology referral",
		desc: "Did you know we offer a variety of pathology tests? Request a test, take the form to your local collection centre and the results will be sent to you in a few days.",
		img: "patho-referral-home.jpg",
		link: "/c/pharm/patho",
		primaryBtn: "Request a referral"
	}
]

const faq_data = [
	{
		title: 'What is InstantScripts?',
		content: <>
				<p>Founded in Melbourne in 2018, InstantScripts has helped hundreds of thousands of Australians access health and medical services from the comfort of their homes.</p>
				<p>We are 100% owned by Wesfarmers, one of the largest and most respected companies in Australia.</p>
				<p>Accessing prescriptions for everyday medications, speaking to a doctor, obtaining a medical certificate or getting blood test requests are just some of the online services offered by InstantScripts.</p>
				<p>Our large team of Australian-registered doctors work seven days a week and we take great care in providing the highest quality of service to all communities.</p>
				<p>We are proud to be able to support the overall healthcare industry to ensure all Australians get the care they need, when they need it.</p>
			</>
	},
	{
		title: 'Who can use InstantScripts?',
		content: <>
			<p>Anyone of any age in Australia with a Medicare card or IHI number can access InstantScripts for <Link to="/telehealth-consultations/">Telehealth Consultations</Link>.</p>
			<p>However for Online Prescriptions, Medical Certificates, Blood Tests and Specialist Referrals, you must be 18 years old or over.</p>
			</>
	},
	{
		title: 'Where are InstantScripts doctors?',
		content: <>
				<p>Every InstantScripts doctor is an AHPRA-registered and fully-insured doctor based in Australia.</p>
				<p>Every doctor must adhere to the highest clinical standards and your doctor’s prescriber number will be written on any prescription that is prescribed.</p>
				</>
	},
	{
		title: 'What medications are available through InstantScripts?',
		content: <>
			<p>You can search for your regular medication by visiting our <Link to="/online-scripts/">Online Prescription</Link> service.</p>
			<p>You can then fill out a digital medical consultation form which will be reviewed by one of our Australian-registered doctors.</p>
			<p>If approved, our doctors will issue you a prescription which you can take to any pharmacy or have the medication delivered to your home.</p>
			<p>If your medication is not listed, or you need to speak to a doctor about your condition, you can request a <Link to="/cat/DocCons">Telehealth Consultation</Link></p>
			<p>Our doctors will not prescribe Schedule 8 or Schedule 4D medications. Please do not request an online doctor consultation via our service if you require these medications.</p>
			</>
	},
	{
		title: 'Who supplies the medication?',
		content: <>
			<p>If you have been prescribed medication you will receive an eScript that can be brought to any pharmacy of your choice.</p>
			<p>If you would like to have your medication delivered to you, InstantScripts has partnered with EverMed for our medication deliveries.</p>
			</>
	},
	{
		title: 'What happens once my prescription is approved?',
		content: <>
				<p>Once your prescription is approved, a QR code will be sent to your mobile phone and email that you can take to any pharmacy for dispensing of your medication.</p>
				<p>If you requested delivery, the medication will be delivered to your door in the time frame notified when ordering your medication.</p>
			</>
	},
	{
		title: 'Am I guaranteed to receive a prescription?',
		content: <>
			<p>InstantScripts upholds the highest standards of healthcare. Online Prescriptions will only be issued if deemed appropriate by our doctors.</p>
			<p>There are various reasons why you may be declined access for a prescription, such as your responses to our digital medical consultation, your medical history and more.</p>
			<p>If you would like to speak to a doctor about your condition, you can request a <Link to="/cat/DocCons">Telehealth Consultation</Link> and a doctor will decide if a prescription is safe to provide.</p>
			</>
	},
	{
		title: 'Are InstantScripts services Medicare bulk billed?',
		content: <>
				<p>InstantScripts offers highly competitive flat-fee pricing for medical services that is often cheaper than out-of-pocket expense on Medicare-funded services.</p>
				<p>The Australian Government does not permit Medicare rebates for any telehealth provider where a face-to-face consultation has not previously occurred in the last 12 months. This means you cannot claim your InstantScripts consultation with Medicare.</p>
			</>
	},
	{
		title: 'Do you provide prescription repeats?',
		content: <>
			<p>While only single prescriptions are available through <Link to="/online-scripts">Online Prescriptions</Link>, you can still access repeat prescriptions through InstantScripts.</p>
			<p>If you have one of our selected chronic conditions, you can access repeat prescriptions with home delivery through our <Link to="/treatment-plans/">Treatment Plans</Link>.</p>
			<p>For all other conditions, you can discuss repeat prescriptions with a doctor by requesting a <Link to="/cat/DocCons/">Telehealth Consultation</Link>.</p>
			</>
	},
	{
		title: 'Can I get a backdated Medical Certificate?',
		content: <>
			<p>Our online <Link to="/c/pharm/certs/">Medical Certificates</Link> can only be issued for the day that it is requested. Our doctors cannot backdate the date that the certificate is issued.</p>
			<p>However, the doctor may consider providing a certificate to cover a specified time period that you have been unwell for, via a <Link to="/cat/DocCons/">Telehealth Consultation</Link>.</p>
			</>
	}
]

const HeaderHomeWrapper = ({ user }) => {
	return (
		<section className={cls(!user?.user_in && "xl:bg-is2-blue w-full h-full")}>
			<HeaderHome user={user} />
		</section>
	);
}

const ReviewRating = ({ clsn }) => {
	const total = 4.7;
	const stars_empty = 5 - Math.ceil(total);
	const perc = Math.round((total - parseInt(total)) * 100);

	return (
		<div className={cls("bg-white", clsn)}>
			<svg className="w-0 h-0" viewBox="0 0 24 24"><defs>
				<mask id="perc">
					<rect x="0" y="0" className="h-full w-full fill-white" />
					<rect x={String(perc) + '%'} y="0" className="h-full w-full fill-[#3d3d3d]" />
				</mask>
				<symbol className="h-fit" xmlns="http://www.w3.org/2000/svg" id="star">
					<path d="M12.5212 0.447798L15.8362 7.07613L22.2129 7.71446C22.552 7.74763 22.7789 7.92463 22.8937 8.24538C23.0085 8.56613 22.9455 8.84696 22.7045 9.0878L17.4495 14.3411L19.3979 21.4795C19.4889 21.8159 19.3997 22.0945 19.1302 22.3155C18.8607 22.5365 18.57 22.5695 18.2579 22.4145L11.7995 19.1845L5.3412 22.4178C5.02912 22.5736 4.73837 22.5409 4.46879 22.3195C4.19929 22.098 4.11062 21.8192 4.20287 21.4828L6.14954 14.3411L0.894537 9.08446C0.653621 8.84363 0.590538 8.5628 0.705371 8.24205C0.820204 7.9213 1.04712 7.7443 1.3862 7.71113L7.76287 7.0728L11.0779 0.444464C11.2271 0.147297 11.468 -0.000869237 11.8005 -0.000119237C12.133 0.000630763 12.3733 0.149964 12.5212 0.447798Z" />
				</symbol>
			</defs>
			</svg>

			<Container>
				<p className="font-normal text-center text-is2-body !mb-1">We’re rated on average {total}/5</p>
				<div className="flex flex-col items-center justify-center gap-1 pb-3 sm:pb-2">
					<div className="flex mt-2">
						{Array(Math.floor(total)).fill().map((el, i) => <svg key={i} className="h-6 w-6"><use href="#star" className="fill-is2-yellow-500"></use></svg>)}
						{perc ? <svg className="h-6 w-6"><use href="#star" mask="url(#perc)" className="fill-is2-yellow-500"></use></svg> : null}
						{Array(stars_empty).fill().map((el, i) => <svg key={i} className="h-6 w-6"><use href="#star" className="fill-is2-yellow-500"></use></svg>)}
					</div>
				</div>
			</Container>
		</div>
	)
}

const ConditionCardWrapper = () => {
	const path = '/images/v2/speak-to-doc-img/'
	const cardsData = [
		{
			title: 'Anxiety and depression',
			imageUrl: `${path}mental-health-hero.jpg`,
			url: '/c/pharm/-LMrOQSVQMBVgUqe3y2A'
		},
		{
			title: 'Cold and flu',
			imageUrl: `${path}cold-and-flu-hero.jpg`,
			url: '/c/pharm/-N9YuK8ZZsDznRtdISNF'
		},
		{
			title: 'Pain and inflammation',
			imageUrl: `${path}pain-or-illness-hero.jpg`,
			url: '/c/pharm/-LJn8ZK_ufxt8fWpDyl8'
		},
		{
			title: 'Weight loss',
			imageUrl: `${path}weight-loss-hero.jpg`,
			url: '/c/pharm/-N1fTGgW2ONhP0Zurrnn'
		},
		{
			title: 'Asthma',
			imageUrl: `${path}asthma-hero.jpg`,
			url: '/c/pharm/-LJVyR5Pdls_ydzW8sRk'
		},
		{
			title: 'Blood pressure',
			imageUrl: `${path}blood-pressure-hero.jpg`,
			url: '/c/pharm/-LJlvQo2RunU9oi9abk5'
		},
		{
			title: 'Sexual health',
			imageUrl: `${path}sexual-health-hero.jpg`,
			url: '/c/pharm/-LwGrL-z9Tx_cVxQH088'
		},
		{
			title: 'Skin treatments',
			imageUrl: `${path}skin-treatment-hero.jpg`,
			url: '/c/pharm/-LJVyeBvRW_BnUCsx0kw'
		},
		{
			title: 'Menopause',
			imageUrl: `${path}menopause-hero.jpg`,
			url: '/c/pharm/hrt'
		},
		{
			title: 'Alternative therapy',
			imageUrl: `${path}alternative-therapy-hero.jpg`,
			url: '/m/pharm/subscr/DocConsICann'
		}
	];

	const searchIcon = "https://storage.googleapis.com/instant-med-public/v2/search-blue.svg"

	return (
		<section className="w-full px-6 py-12 bg-white">
			<div className="w-full lg:max-w-[1124px] sm:px-4 mx-auto">
				<h2 className="text-center text-is2-gray-800 mt-1 sm:mt-2 mb-[52px] ">Speak to our doctors about:</h2>
				<ConditionCardGrid items={cardsData} />
				<ConditionCardCarousel items={cardsData} />
				<div className="flex flex-col lg:flex-row items-center justify-center gap-4 my-10">
					<Button href="#" onClick={() => app.trigger(app.events.SIDEMENU, {view:'Conditions'})} basic className="!pl-4 !py-3">
						<div className="flex items-center">
							<img
								src={searchIcon}
								alt="Search Icon"
								className="h-[30px]"
								aria-hidden="true"
							/>
							<p className="ml-2">Find your condition</p>
						</div>
					</Button>
					<p className="mb-0">or<span className="lg:hidden">, <Link to="/c/pharm/DocCons" className="text-is2-blue">speak to a doctor</Link></span></p>
					<Button as={Link} to="/c/pharm/DocCons" basic className="!pl-4 !py-3 !hidden lg:!block">
						<div className="flex items-center">
							<SIcon name="doc-steth" cls="text-is2-blue h-[30px] w-[30px] hidden lg:block" bold />
							<p className="ml-2">Speak to a doctor</p>
						</div>
					</Button>
				</div>
			</div>
		</section>
	);
}

const InfoHomeCardsWrapper = () => {

	const infoHomeItems = [
		{
			icon: `${img_path}/heart-golden-icon.svg`,
			headingIntro: "Stay home and relax",
			title: "Healthcare at home",
			img: "https://storage.googleapis.com/instant-med-public/v2/hero-info-img-1.png",
			descriptions: [
				"Don't leave home, drive, or be around sick people in a waiting room.",
			],
			postDescription: "",
		},
		{
			icon: "https://storage.googleapis.com/instant-med-public/v2/cross-golden-icon.svg",
			headingIntro: "We deliver in under 4 hours",
			title: "Fast medication delivery",
			img: "https://storage.googleapis.com/instant-med-public/v2/hero-info-img-2.png",
			descriptions: [
				`If you want medication fast, we can deliver it to you in <br class="hidden lg:block"><span class="underline underline-offset-2 decoration-wavy decoration-[#FBDDB3] font-bold">less than 4 hours*</span>.`,
				"eScripts and Click & Collect also available.",
				`<span class="text-sm">*4-hour delivery only available to metro locations and at select times <br class="hidden lg:block">for an added fee.</span>`,
			],
			postDescription: "",
		},
		{
			icon: "https://storage.googleapis.com/instant-med-public/v2/cross-golden-icon.svg",
			headingIntro: "Night doctors available",
			title: "Open 24/7",
			img: "https://storage.googleapis.com/instant-med-public/v2/hero-info-img-24-7.jpg",
			descriptions: [
				"Don't wait till the morning. Our doctors are here to help at any time.",
			],
			postDescription: "",
		},
	]

	const singleInfoItems = [
		{
			headingIntro: "Over 150 doctors",
			title: <>Real doctors,<br /> real care</>,
			img: "https://storage.googleapis.com/instant-med-public/v2/dr-sarah.jpg",
			icon: "https://storage.googleapis.com/instant-med-public/v2/ahpra-nations-boards-icon.png",
			postDescription: "",
			overlayText: "Dr. Sarah Cavanagh, Chief Medical Officer",
			overlayProfession: "Chief Medical Officer",
			overlayName: "Dr. Sarah Cavanagh",
			overlayCompany: "InstantScripts",
			descriptions: [
				"Every InstantScript doctor is Australian based and registered with the  ",
			],
			overlayLinkText: "Australian Health Practitioner Regulation Agency.",
			overlayLinkUrl: "https://www.ahpra.gov.au/"
		},
	]

	return (
		<>
			<section className="grid grid-cols-1 py-[96px] lg:py-[160px] sm:px-10 bg-is2-yellow-100">
				<div className="w-full lg:max-w-[1124px] px-4 mx-auto">
					<img src={`${img_path}/heart-golden-icon.svg`} className="mx-auto hidden lg:block" />
					<h2 className="!text-is2_4xl lg:!text-is2_5xl text-center text-is2-gray-800 mt-0 lg:mt-[60px] mb-[48px] lg:mb-[96px]">We make healthcare convenient...</h2>
					{infoHomeItems.map((item, i) => {
						return (
							<InfoHomeCard key={i} {...item} isEven={Boolean(i % 2 !== 0)} />
						)
					})}
				</div>
			</section>
			<section className="grid grid-cols-1 py-[120px] sm:px-10 bg-white">
				<div className="lg:max-w-[1124px] px-4 mx-auto">
					{singleInfoItems.map((item, i) => {
						return (
							<SingleInfoCard key={i} {...item} isEven={Boolean(i % 2 !== 0)} />
						)
					})}
				</div>
			</section>
		</>
	);
}

const StepsTabsWrapper = () => {
	const elements = [
		{
			name: "Speak to a doctor",
			id: "doctor",
			content: <SpeakToADoctorCopy />,
		},
		{
			name: "Prescriptions",
			id: "prescriptions",
			content: <PrescriptionsCopy />,
		},
		{
			name: "Medical certificates",
			id: "certificates",
			content: <MedicalCerfiticateCopy />,
		},
		{
			name: "Pathology",
			id: "pathology",
			content: <PathologyCopy />,
		},
		{
			name: "Specialist referrals",
			id: "referrals",
			content: <SpecialistCopy />,
		},
	];

	return (
		<div className="w-full px-6 py-28 bg-is2-yellow-100">
			<div id="steps-tabs-wrapper" className="w-full lg:max-w-[1124px] mx-auto">
				<p className="font-normal text-center text-is2-gray-350 mb-1 text-is2_md">Fast and easy</p>
				<h2 className="text-center text-is2-gray-800 pb-4 sm:pb-6">How it Works</h2>
				<StepsTabs elements={elements} />
			</div>
		</div>
	)
}

const PrescriptionsWrapper = () => {
	return (
		<div className="px-6 py-9 rounded bg-white hidden lg:block">

		</div>
	)
}

const YourHealthWrapper = () => {
	return (
		<div className="hidden lg:block">
			<TextImageBox
				title="Understand your health"
				desc="Understand and monitor how healthy you are. Take our free health quiz to setup your health profile."
				content={<HealthMonitor />}
				btnText="Get Started" />
		</div>
	)
}


const BannersWrapper = ({ prof, layout, title, clsn }) => {

	const expiry = 1000 * 60 * 60 * 24;
	const [dismissed, setDismissed] = useState([]);

	const bannersList = useMemo(() => {
		const articlesToShow = (
			layout === "main" && ['consults', 'certs', 'delivery', 'patho']
			|| layout === "side" && ['refer'].concat(...(!prof?.onepass ? ['onepass'] : []))
		);

		const isDismissed = (id) => dismissed.includes(id) || JSON.parse(sessionStorage.getItem(`${id}_home_dismissed`))?.expiry > new Date().getTime();

		return articles.filter(article => articlesToShow.includes(article.id) && !isDismissed(article.id))
	}, [dismissed]);

	const hideBanners = (id) => {
		setDismissed([...dismissed, id]);
		sessionStorage.setItem(
			`${id}_home_dismissed`,
			JSON.stringify({ expiry: new Date().getTime() + expiry })
		);
	}

	return (<div className={cls(clsn, layout != "side" && "bg-white px-6 pt-9 rounded")}>
		{title && <h2 className={cls(layout === "main" ? "mb-9" : "mb-4", "hidden lg:block")}>{title}</h2>}
		<div className="flex flex-wrap gap-2 lg:gap-6">
			{bannersList.map((article, i) => {
				return (
					<TextImageBox key={i} {...article} col={layout === "side"} onDismiss={(id) => hideBanners(id)} />
				)
			})}
		</div>
	</div>
	)
}

const Partners = () => {

	const partners_list =
		[
			{
				name: 'NSW Health',
				img: img_path + '/partners/NSW-health.svg',
				link: 'https://www.health.nsw.gov.au/',
				width: 'w-[108px]'
			},
			{
				name: 'Priceline',
				img: img_path + '/partners/Priceline-pharmacy.svg',
				link: 'https://www.priceline.com.au/',
				width: 'w-[116px]'
			},
			{
				name: 'Onepass',
				img: img_path + '/partners/Onepass.svg',
				link: 'https://onepass.com.au/',
				width: 'w-[103px]'
			},
			{
				name: 'RACGP',
				img: img_path + '/partners/Racgp.svg',
				link: 'https://www.racgp.org.au/',
				width: 'w-[103px]'
			},
		]
	return <div className="bg-white flex flex-col items-center px-6 py-[60px] lg:py-[120px] w-full">
		<h2 className="text-center text-is2-gray-300 mb-8">Join over 2 million InstantScripts users</h2>
		<ReviewRating />

		<Button primary className="!my-[52px]" onClick={() => app.trigger(app.events['REQUEST_JOIN'])}>Create your account</Button>

		<p className="text-center text-is2-body mb-7 mt-0">We’re partnered with:</p>
		<div className="flex flex-wrap space-between items-center w-full md:justify-center md:space-x-[60px]">
			{partners_list.map((el, i) => <a href={el.link} className="w-1/2 md:w-fit mb-7 md:mb-0" target="_blank" key={i}><img className={cls("mx-auto", el?.width)} src={el.img} /></a>)}
		</div>
	</div>
}


const Help = () => {
	return <div className="bg-is2-yellow-100 pt-14 pb-12 md:py-9 px-6 lg:rounded-[28px]">
		<h3 className="text-left lg:text-center mb-6">Need some help?</h3>
		<div className="flex flex-col md:flex-row gap-4 items-center md:justify-center">
			<Button basic className="!w-full" as={"a"} href="https://help.instantscripts.com.au/en/">View our help articles</Button>
			<p className="mb-0 text-sm md:hidden">or</p>
			<Button basic className="!w-full" onClick={() => window.Intercom('show')}>Chat to us</Button>
		</div>
	</div>
}

const FooterWrapper = ({clsn, bg}) => {
	return (
		<div className={clsn||''}>
			<FooterDownload bg={bg} />
		</div>
	)
}

const HomepagePatient = ({ publicView = false, about = false }) => {

	const user = useUser()
	const [searchParams] = useSearchParams();
	const [prof] = useProfile()
	const [scrollY, setScrollY] = useState(0);
	const newUser = !prof?.hist.length;
	const user_in = user.user_in
	const isMobile = !useBreakpoint('lg');

	useEffect(() => {
		searchParams.get('oidc') && user_model.onepass_login(true);
	}, []);

	if (app.settings.is_ionic && !app.state.loaded) {
		return <Loader active />
	}

	useEffect(() => {
		const handleScroll = () => { setScrollY(window.scrollY) };
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	});

	return <div className={cls("flex flex-col", user_in ? "lg:flex-row" : "xl:bg-is2-blue no-user")}>
		{/* {!__IONIC__ && <Hero user_in={user_in && !publicView} about={about} />} */}

		<HeaderHomeWrapper user={user} />

		{user_in ?

			<div className="lg:p-4 flex justify-center gap-4 w-full">
				<div className="lg:mx-auto lg:py-12 flex flex-col gap-3 lg:px-0 md:max-w-[689px] xl:max-w-[737px]">

					<Welcome clsn="hidden lg:block" />


					{newUser && <>
						<AccountOnboarding />
						{app.acl.is_usr && <MFA  />}
					</>}

					<div className="hidden lg:block"><CommonAlerts /></div>

					{app.site_status?.notifications?.enable_banners?.includes?.(app.runtime?.app) && (
						<div className="hidden lg:block">
							<NotificationBannersApp />
						</div>
					)}

					<ActiveConsult clsn="block" skip={isMobile ? 1 : 0} />

					<LastOrder clsn="block lg:hidden bg-white px-6 py-9" />

					{!newUser && <div className="h-fit block md:hidden"><BannersWrapper layout="side" /></div>}

					<LastOrder clsn="lg:block hidden" />

					{!newUser && <ActivePrescriptionsDashboard cardQty={2} />}

					<BannersWrapper layout="main" title="What's new:" />

					<FooterWrapper bg="bg-is2-blue-50" clsn="lg:hidden" />

					<Help />

				</div>

				{!newUser && <div className="h-fit xl:max-w-[363px] hidden menu-xl:block"><BannersWrapper layout="side" /></div>}

			</div>

			:

			<div className="home-main xl:z-[12] bg-white relative">

				<img style={{ transform: `translateY(${scrollY * -0.2}px)` }} className="w-[268px] h-auto absolute right-[calc(50%+260px)] -top-[180px] hidden xl:block rotate[-167.32deg]" src={`/images/v2/home/large-heart-1.png`} />

				<ReviewRating clsn="pt-10 sm:pt-16 sm:pb-0" />

				<ConditionCardWrapper />

				<InfoHomeCardsWrapper />

				<StepsTabsWrapper />

				<Partners />

				<FooterWrapper />

				<FaqSection data={faq_data} title={<>Frequently<br /> asked questions</>} />

			</div>
		}

	</div>
};

export default HomepagePatient;

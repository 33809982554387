import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import NotificationsHeader from "../NotificationsHeader";
import NotificationsList, { getPaginatedNotifications } from "../NotificationsList";

const PAGE_SIZE = 7;

const NotificationsListApp = ({
    show,
    notifications,
    loading,
    setNotificationRead,
    setNotificationsRead,
    onClose,
    autoFocus = false,
    usePlugin,
}) => {
    const escapeBlockedRef = useRef(false);
    const navigate = useNavigate();

    const [hasUnreadButton, setHasUnreadButton] = useState(false);

    const handleOnClose = useCallback(() => {
        if (!hasUnreadButton) {
            const paginatedNotifications = getPaginatedNotifications(notifications, 1, PAGE_SIZE);
            const notification_ids =
                paginatedNotifications
                    .filter(n => !n.read && !n.overrides?.persistent)
                    .map(n => n.notification_id);
            setNotificationsRead(notification_ids, true);
        }
        onClose();
    }, [
        notifications,
        setNotificationsRead,
        onClose,
        hasUnreadButton,
    ]);

    const handlePageChange = useCallback(({ previousPageNotifications }) => {
        if (!hasUnreadButton) {
            const notification_ids =
                previousPageNotifications
                    .filter(n => !n.read && !n.overrides?.persistent)
                    .map(n => n.notification_id);
            setNotificationsRead(notification_ids, true);
        }
    }, [setNotificationsRead, hasUnreadButton]);

    const handleNotificationClick = (notification) => {
        setNotificationRead(notification.notification_id, true);

        if (notification.link) {
            navigate(notification.link, notification.link_options || undefined);
            handleOnClose();
        }
    };

    const containerRef = useRef(null);
    useEffect(() => {
        if (!show) {
            document.body.style.overflow = '';
            return () => { };
        }

        document.body.style.overflow = 'hidden';

        const handleKeyDown = (event) => {
            if (!show) return;

            if (event.key === 'Escape' && !escapeBlockedRef.current) {
                handleOnClose();
            } else if (event.key === 'u') {
                setHasUnreadButton(b => !b);
            }
        };

        const handleClickOutside = (event) => {
            const toggleElement = event.target.closest('[data-toggle-notifications="true"]');
            if (containerRef.current &&
                !containerRef.current.contains(event.target) &&
                !toggleElement) {
                handleOnClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);

        return () => {
            document.body.style.overflow = '';
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [show, handleOnClose]);

    const allCount = notifications.length;
    const newCount = notifications.filter(n => !n.read).length;

    return (
        <div
            className="notifications-list-container relative isolate z-[100]"
            ref={containerRef}
        >
            <Transition
                show={show}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className="notifications-list-container--overlay fixed inset-0 bg-white/50 z-[99]"
                    onClick={handleOnClose}
                />
            </Transition>

            <div className={`
                fixed left-0 right-0 top-[4.25rem] 
                sm:absolute sm:right-0 sm:left-auto sm:top-12
                ${show ? 'pointer-events-auto' : 'pointer-events-none'}
                z-[100]
                w-full max-w-[38rem]
            `}>
                <Transition
                    show={show}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <section
                        className={`
                            notifications-list-container--content
                            w-full max-w-[38rem]
                            h-[calc(100vh-5rem)]
                            overflow-y-auto rounded-none
                            shadow-xl shadow-inner bg-white
                            border-t-2 border-[#ECF4FE]
                            sm:h-auto sm:max-h-[calc(100vh-3rem)]
                            sm:rounded-md
                            sm:border sm:border-[#CCE0FC]/50
                        `}
                    >
                        <h3 className="sr-only">Notifications</h3>
                        <NotificationsHeader
                            allCount={allCount}
                            newCount={newCount}
                            onClose={handleOnClose}
                        />
                        <div className="sm:p-1 p-0 !pb-4">
                            <NotificationsList
                                notifications={notifications}
                                loading={loading}
                                setNotificationRead={setNotificationRead}
                                autoFocus={autoFocus}
                                usePlugin={usePlugin}
                                onNotificationClick={handleNotificationClick}
                                onPageChange={handlePageChange}
                                onClose={handleOnClose}
                                pageSize={PAGE_SIZE}
                                hasUnreadButton={hasUnreadButton}
                            />
                        </div>
                    </section>
                </Transition>
            </div>
        </div>
    );
};

export default NotificationsListApp;
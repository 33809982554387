import React, { useState, useEffect } from "react";
import script_model from "models/script_model";
import user_model from "models/user_model";
import UniFormMed from "views/med/UniFormMed";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import Photos from "xAppLib/Photos/Photos";
import Alert from "views/NUI/Alert";
import BookingFields from "views/med/Sections/Forms/BookingFields";
import { CheckBmi, make_bmi_calculator } from "./CheckBmi";

const DocCons = (props) => {
	const { med_data, script_type, req_type, Section, enabled, visible, has_bmi_requirement, has_weight_requirement } = props
	const [ fields, setFields] = useState([]);
	const hide_desc = ['docrefr', 'pathoreq', 'medcons','medbuy', 'medclick', 'qualcons', 'qualclick', 'qualbuy', 'exprbuy', 'exprcons', 'exprclick'].includes(script_type) && req_type != "medcert"
	const hide_section = ['med-cert', 'med-cert-school', 'med-cert-uni', 'med-carer'].includes(med_data.key);

	const isCosmMajorComp = script_type === 'doccons' && req_type === 'cosm' && med_data.m === 'DocConsCosmMajor'
    const CALL_RESTRICTION_REQUIRED = script_model.time_to_offer_call_restriction_hours() && !props.formData?.appointment?.start && !['mhcp'].includes(req_type);

	useEffect(() => {
		// Rather than have the rendering component figure out the context in which these fields are being used, and
		// therefore being responsible for setting up the field validation etc, move the responsibility up to where
		// the fields are being added to the form (i.e. here), and let the renderer focus on rendering
		// In an ideal world we wouldn't need to "pre-register" fields, the mere act of rendering the field would
		// register it along with any specific handling it needs. But that's not how our forms work, but the fact
		// remains the field config should be where the field registration occurs, and that's here.
		const bmi_context = {
			has_bmi_requirement,
			has_weight_requirement,
			calculator: make_bmi_calculator(med_data.xtra)
		};

		const appointment_context = {
			has_appointment: ['icann', 'mhcp', 'docconswl'].includes(req_type),
			fallback_required: ['icann'].includes(req_type)
		}

		const fields = [...script_model.COMM_DCNS_FIELDS].map(({...field}) => {
			if (app.settings.icann && field.name === "cons_phone") field.label = "Please enter your best contact number that our doctors will reach you on."
			if (app.settings.icann && field.name === "cons_desc") field.label = "Why are you seeking this treatment today?"
			if (req_type === "medcert" && field.name === "cons_desc") field.label = "What are your symptoms?"
			if ((['medcons','exprcons','qualcons', 'docrefr', 'pathoreq'].includes(script_type) || req_type == 'cosm') && field.name === "cons_phone") field.valid_not_required = true
			if (hide_desc && field.name === 'cons_desc') field.valid_not_required = true
			if (req_type == 'cosm' && field.name === 'cons_desc') field.valid_not_required = false
			if (field.name === 'dcs_cert_dates' && req_type === 'dcs_cert') field.valid_not_required = false;
			if (field.name === 'dcs_refr_spec' && req_type === 'dcs_refr') field.valid_not_required = false;
			if (field.name === 'dcs_patho_dets' && req_type === 'dcs_patho') field.valid_not_required = false;
			if (req_type === 'dcs_scr' && ['dcs_med_desc'].includes(field.name)) field.valid_not_required = false;
            if (field.name === 'call_hour_restriction' && CALL_RESTRICTION_REQUIRED) field.valid_not_required = false;
			if(isCosmMajorComp && field.name === 'cons_phone') field.valid_not_required = false;

			if (['appointment_type', 'appointment', 'call_time', 'dont_call_time'].includes(field.name)) {
				field._ctx = appointment_context;
			}
			if (/^cons_bmi_/.test(field.name)) {
				field._ctx = bmi_context;
			}

			return field
		})
		setFields(fields);
	},[has_weight_requirement])

	return !hide_section && (
		<UniFormMed
			{...props}
			section="doccons"
			fields={fields}
			>
			{(values, valids, uf_this, fields) => {
				const args = {values, valids, uf_this}

				if (!(enabled && visible)) {
					return null;
				}
				const headerType = script_type === 'pathoreq' ? 'Pathology' : !['docrefr', 'medcons', 'exprcons', 'qualcons', 'medbuy', 'medclick', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick'].includes(script_type) ? 'Consultation' : '';
				const header = headerType ? `${headerType} request details` : 'Request details';

				return <Section>
						
						<Section.Header>
							{header}
						</Section.Header>

						{ app.settings.ileg &&
							<Alert
								content={"If you require multiple specialist referrals, please list all of the specialists required in the description field below"}
								warning
							/>
						}

						<Section.Content>
                            {["medcert"].includes(req_type) && (
								<p>
									Please provide additional information to
									help our doctors review your request. This
									will not appear on the Medical Certificate.
								</p>
                            )}
							{ !['docrefr', 'pathoreq'].includes(script_type) &&
								<>
									{
										(
											(!['medcons','medbuy', 'medclick'].includes(script_type) && req_type != 'cosm') ||
											isCosmMajorComp
										)
										&& <>
											<UniFieldSUI fl={fields.cons_phone} {...args} />
											{app.settings.iscr && <p>{fields.cons_phone?.extra}</p>}
										</>}
									{!hide_desc && <UniFieldSUI fl={fields.cons_desc} {...args} />}
								</>
							}

							{ ['dcs_cert'].includes(req_type) &&
								<UniFieldSUI fl={fields.dcs_cert_dates} {...args} />
							}

							{ ['dcs_scr'].includes(req_type) && <>
								<UniFieldSUI fl={fields.dcs_med_desc} {...args} />
							</>}

							{ ['dcs_refr'].includes(req_type) && <>
								<UniFieldSUI fl={fields.dcs_refr_spec} {...args} />
								<UniFieldSUI fl={fields.dcs_refr_dets} {...args} />
							</>}

							{ ['dcs_patho'].includes(req_type) && <>
								<UniFieldSUI fl={fields.dcs_patho_dets} {...args} />
							</>}

							{ ['docrefr'].includes(script_type) && 
								<>
									<span>If you have a particular doctor you would like the referral addressed to, please enter their name below (optional)</span>
									<UniFieldSUI fl={fields.cons_ref_name} {...args} show_label={false} />
									{/*<UniFieldSUI fl={fields.cons_ref_email} {...args} />*/}
								</>
							}

							{ ['docconsref', 'ileg'].includes(req_type) && 
								<>
									<UniFieldSUI fl={fields.cons_ref_spec} {...args} />
									<UniFieldSUI fl={fields.cons_ref_name} {...args} />
									<UniFieldSUI fl={fields.cons_ref_email} {...args} />
								</>
							}

							{['icann'].includes(req_type) && (
								<BookingFields
									type="icann"
									fallback={() => (
										<UniFieldSUI fl={fields.call_time} {...args} />
									)}
									resetFields={["call_time"]}
									control={{fields, args}}
									ufThis={uf_this}
								/>
							)}

							{ ['mhcp'].includes(req_type) && 
								<>
									<BookingFields
										type="mhcp"
										fallback={() => (
											<UniFieldSUI fl={fields.dontcall_time} {...args} />
										)}
										resetFields={["dontcall_time"]}
										control={{fields, args}}
										ufThis={uf_this}
									/>
									<UniFieldSUI fl={fields.cons_psy_known} {...args} />
									{values.cons_psy_known === 'yes' ? <>
										<UniFieldSUI fl={fields.cons_psy_name} {...args} />
										<UniFieldSUI fl={fields.cons_psy_email} {...args} />
									</> : <p>
										{app.user?.prof?.yogp?.name ? <>We will forward the details of this Mental Health Care Plan to your GP, <b>{app.user?.prof?.yogp.name}, {app.user?.prof?.yogp.organisation}</b>. If you wish to change your GP, you can do so by editing your profile.</> 
										: <>
											As you have not provided us the details of your psychologist, 
											we will provide a referral to our telehealth psychology partner <a href="https://www.mymirror.com.au/" target="_blank" className="underline text-blue-600">My Mirror</a>.
											You can provide us with the details of your psychologist by editing your profile above.
										</>}
									</p>}
								</>
							}

							{ has_weight_requirement && <UniFieldSUI fl={fields.cons_bmi_weight} {...args} />}

							{ has_bmi_requirement && <CheckBmi {...{fields, med_data, ...args}} /> }

							{['docconswl'].includes(req_type) && (
								<BookingFields
									type="docconswl"
									fallback={() => (
										app.site_status?.booking?.types?.docconswl?.enabled
										? (
											<div className="py-4">
												Next available consultation.
												<br />
												One of our doctors will get back to your soon.
											</div>
										) : null
									)}
									resetFields={[]}
									control={{fields, args}}
									ufThis={uf_this}
								/>
							)}


							{ ['docconssc'].includes(req_type) && 
								<>
									<UniFieldSUI fl={fields.cons_sc_day} {...args} />
									<UniFieldSUI fl={fields.cons_sc_length} {...args} />
								</>
							}
							{ ['docconssl'].includes(req_type) &&
								<>
									<UniFieldSUI fl={fields.cons_bmi_height} {...args} valid_not_required={true} />
									<UniFieldSUI fl={fields.cons_bmi_weight} {...args} valid_not_required={true} />
								</>
							}

							
							{app.acl.is_spon &&
								user_model.check_access("phts_add") && (
									<div className="field">
										<label>
											Please include any documents to support your request.
										</label>
										<Photos
											acceptedTypes={[
												{
													value: ".pdf",
													label: "PDF",
												},
												{
													value: "image/*",
													label: "PNG, JPG",
												},
												{
													value: ".doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
													label: "DOCX",
												},
											]}
											uploadType="attachment"
											show_disclaim
											inline
											target="uplass"
											data={values["phts"] || []}
											onChange={(ps) =>
												uf_this.handleInputChange(
													null,
													{ name: "cons_phts", value: ps }
												)
											}
										/>
									</div>
								)}

                            {!['mhcp'].includes(req_type) &&
                                CALL_RESTRICTION_REQUIRED &&
                                <UniFieldSUI fl={fields.call_hour_restriction} {...args} />
                            }
						</Section.Content>
				</Section>;
			}}
		</UniFormMed>
	);
};

export default DocCons;

import React from "react";
import { Container } from "semantic-ui-react";
import NotificationBannerItem from './NotificationBannerItem';

const NotificationBanners = ({
    notifications,
    loading,
    setNotificationRead,
    usePlugin,
}) => {
    if (loading || notifications.length === 0) return null;

    return (
        <div className="notification-banners shadow-inner border-b border-gray-200 bg-white">
            <Container>
                <div className="flex flex-col gap-3 mx-auto p-4">
                    {notifications.map((notification) => (
                        <NotificationBannerItem
                            key={notification.notification_id}
                            notification={notification}
                            onDismiss={() => {
                                setNotificationRead(notification.notification_id, true);
                            }}
                            usePlugin={usePlugin}
                        />
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default NotificationBanners;

import React from "react";
import { Icon, Label } from "semantic-ui-react";

const NotificationIndicator = ({
    onClick,
    size = "large",
    open = false,
    unreadCount = 0,
}) => (
    <button
        onClick={onClick}
        title={`Notifications ${unreadCount > 0 ? `(${unreadCount} unread)` : ''}`}
        aria-label={`Notifications ${unreadCount > 0 ? `(${unreadCount} unread)` : ''}`}
        aria-expanded={open}
        aria-haspopup="true"
        data-toggle-notifications="true"
        className={`
                notifications---indicator
                notifications---indicator--${open ? 'open' : 'closed'}
                bg-transparent
                relative
                inline-flex
                items-center
                justify-center
                p-1
                focus:outline-none
                focus:ring-2
                focus:ring-blue-500
                focus:ring-offset-2
                rounded-full
            `}
    >
        <Icon
            name={open ? "bell" : "bell outline"}
            size={size}
            aria-hidden="true"
            className={
                open
                    ? "text-notifications-indicatorIcon-active"
                    : "text-notifications-indicatorIcon"
            }
        />
        {unreadCount > 0 && (
            <Label
                circular
                color="orange"
                size="mini"
                className="absolute -top-1 right-0.5"
                aria-hidden="true"
            >
                {unreadCount > 99 ? '99+' : unreadCount}
            </Label>
        )}
    </button>
);

export default NotificationIndicator;
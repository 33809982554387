import React from "react";
import { Icon } from "semantic-ui-react";

const NotificationsHeader = ({
    allCount,
    newCount,
    onClose,
}) => {
    return (
        <div className="notifications-header">
            <div className={`
            notifications-header-main flex justify-between items-center
            bg-notifications-list-header
            py-8 px-6
            `}>
                <h1 className="text-lg md:text-xl m-0 font-bold !text-white cursor-default">Notifications</h1>
                {onClose && (
                    <button
                        className={`
                            appearance-none border-none p-0
                            bg-black/15
                            hover:bg-black/25
                            rounded-full
                            w-10 h-10 flex justify-center items-center
                            p-2
                            ml-auto
                            leading-none
                        `}
                        onClick={onClose}
                        aria-label="Close notifications"
                        title="Close notifications"
                    ><Icon name="close" size="small" className="text-white !m-0" /></button>
                )}
            </div>
            <div
                className={`
                    notifications-header-bar bg-white
                    flex justify-center items-center
                    py-4 px-4
                    gap-10
                    cursor-default
                    text-black/85
                `}
            >
                <div>All ({allCount})</div>
                <div>New ({newCount})</div>
            </div>
        </div>
    );
}

export default NotificationsHeader;
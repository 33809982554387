export function app_runtime(base, location) {
	let get_params_init 
	try {
		get_params_init = new URLSearchParams(location.search);
	} catch(e) {}

	let ref_init
	try {
		ref_init = document.referrer && location.host != new URL(document.referrer).host && document.referrer
	} catch (e) {}

	let sticky_runt;
	try {
		sticky_runt = localStorage.getItem('__runt.sticky');
	} catch (e) {/**/}

	const dflt = {
			name:'InstantScripts',
			app:'iscr',
			mode:'iscr',
			logo:'images/logos/instant-scripts-logo2.svg',

			login_type: ['page', 'popup'][1],

			// optim: {'1_JCh2sTTQK-Qht3c21KoQ':1},
		}
	let extra = {}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------
	//		partners

	if (location.href.startsWith('https://c2u.instantscripts.com.au/') || location.pathname.startsWith('/c2u')) 
		extra = { 
			partn_type: 'c2u',
			mode: 'c2u',
			partn_oid:'qrGpLwI',
			partn_pharm_oid: 'qrGpLwI',
			partn_pharm_detmsg: 'Your script will be sent to Chemist2U. Following completion of the digital consultation, proceed to chemist2u.com.au to order your medication delivery.',
			start_page:'/c',
			// hide_nav: true,
			// med_allow_nouser: true,
			medbuy_only: true,
		}

	/*
	else if (location.pathname.startsWith('/c2u/'))
		extra = { 
			name:'Chemist2U',
			app:'iscr',
			mode:'c2u',
			partn_oid:'qrGpLwI',
			partn_pharm_oid:'qrGpLwI',
			med_allow_nouser: true,
			start_page:'/c',
			is_bare: true,
			allow_set_usr: true,
			// free_consult: true,
			// medbuy_only: true,
		}

	else if (location.pathname.startsWith('/caia/m/'))
		extra = { 
			name:'Caia',
			app:'iscr',
			mode:'caia',
			partn_oid:'MEDU_3o',
			med_allow_nouser: true,
		}
	*/

	else if (location.pathname.toLowerCase().startsWith('/whitecoat-docconsref'))
		extra = { 
			partn_type: 'ref',
			start_page: '/med/DocConsRef',
			partn_oid: 'kJ1JQes',
			med_allow_nouser: true,
			med_allow_excl: true,
		}

	else if (location.pathname.startsWith('/whitecoat/'))
		extra = { 
			partn_type: 'refdemo',
			name:'Whitecoat InstantScripts',
			// app:'iscr',
			// mode:'whitecoat',
			headerLogo:"/images/logos/whitecoat_is_logo.png",
		}

	else if (location.href.startsWith('https://pharmacyonline.instantscripts.com.au/'))
		extra = { 
			partn_type: 'forpha',
			partn_pharm_oid: 'g9wJlfo',
			partn_pharm_detmsg: 'Your script will be sent to Pharmacy Online. Following completion of the digital consultation, proceed to www.pharmacyonline.com.au to order your medication.',
		}

	else if (get_params_init?.get('poid')=='LwFoiAI' || get_params_init?.get('pnm')?.toLowerCase()=='he') {
		extra = { 
			mode: 'HE',
			partn_type: 'HE',
			partn_oid: 'LwFoiAI',
			allow_set_medform: true,
			// start_page:'/m/pharm/online/prescription',
			// med_allow_nouser: true,
			// medcert_allow_nouser: true,
			// hide_consults: true,
			// phapick_only: true,
		}
	}
	else if (get_params_init?.get('poid')=='-LMbpY7p7i1JMk4Biv9_' || get_params_init?.get('pnm')?.toLowerCase()=='plp') {
		extra = { 
			partn_type: 'PLP',
			partn_oid: '-LMbpY7p7i1JMk4Biv9_',
		}
	}
	else if (get_params_init?.get('poid')) 
		extra = { 
			// partn_type: 'HE',
			partn_oid: get_params_init?.get('poid'),
			// start_page:'/m/pharm/online/prescription',
			// med_allow_nouser: true,
			// medcert_allow_nouser: true,
			// hide_consults: true,
			// phapick_only: true,
		}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------
	//		pharms

	else if (get_params_init?.get("phoid") && get_params_init.get("m")=='mbo')
		extra = { 
			partn_type: 'forpha',
			partn_oid:get_params_init.get("phoid"),
			partn_pharm_oid:get_params_init.get("phoid"),
			med_allow_nouser: true,
			start_page:'/m/pharm/online/prescription',
			is_bare: true,
			allow_set_usr: true,
			// free_consult: true,
			medbuy_only: true,
		}

	else if (get_params_init?.get('phoid') && ['pharmqr'].includes(get_params_init?.get('utm_campaign'))) 
		extra = { 
			partn_type: 'forpha',
			partn_oid:get_params_init.get("phoid"),
			partn_pharm_oid:get_params_init.get("phoid"),
			start_page:'/m/pharm/online/prescription',
		}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------
	//		mini sites

	else if (['cosmapp.instantscripts.com.au', 'icosm.test:9011','instcosm.web.app','instcosm.firebaseapp.com', 'instantcosmetic.com.au', 'instantcosmetics.com.au', 'icosm.com.au', 'd.icosm.com.au','iscr-dev-cosm.web.app'].includes(location.host) || location.pathname.toLowerCase().startsWith('/icosmws') || sticky_runt==='icosm')
        extra = {
			name:'InstantCosmetics',
			app:'icosm',
			mode:'icosm',
			logo:'/icosm/images/icosm-black.png',
			headerLogo:'/icosm/images/icosm-white2.svg',
			sidemenuLogo:'/icosm/images/icosm-white2.svg',
			scripts:['//unpkg.com/aos@2.3.1/dist/aos.js?ver=5.4.2'],
			css:["https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap"]
		}

	else if (['inprep.test:9011','inprep.web.app', 'inprep.firebaseapp.com', 'hivprevent.com.au', 'preventhiv.com.au', 'iprep.com.au'].includes(location.host) || location.pathname.toLowerCase().startsWith('/prepws'))
		extra = { 
			name:'HIV Prevent',
			app:'iprep',
			mode:'iprep',
			headerLogo:'/iprep/images/iprep-white.svg',
			sidemenuLogo:'/iprep/images/iprep-white.svg',
			css:["/iprep/fonts.css"],
			med_allow_excl: false,
			medbuy_only: true,
		}

	else if (['ilegal.test:9011','ilegal.web.app', 'ilegal.firebaseapp.com', 'mylegaldoc.com.au'].includes(location.host) || location.pathname.toLowerCase().startsWith('/ilegws'))
		extra = { 
			name:'My Legal Doc',
			app:'ileg',
			mode:'ileg',
			headerLogo:'/ileg/images/ileg-logo.svg',
			sidemenuLogo:'/ileg/images/ileg-white.svg',
			css:["/ileg/fonts.css"],
			med_allow_excl: true,
			// medbuy_only: true,
		}

	else if (['icann.test:9011','icanna.web.app', 'icanna.firebaseapp.com', 'thegreendoc.com.au', 'greendoc.com.au', 'goodleaf.com.au', 'goodleaf.au', 'benicura.com', 'benicura.com.au', 'benicura.au'].includes(location.host) || location.pathname.toLowerCase().startsWith('/icannws'))
		extra = { 
			name:'Benicura',
			app:'icann',
			mode:'icann',
			headerLogo:'/icann/images/logo_withtagline.svg',
			sidemenuLogo:'/icann/images/logo-transparent.svg',
			css:["/icann/fonts.css", "https://use.typekit.net/nxz6kzj.css"],
			incl_med_cat: [/*'DocConsICann', */'DocConsICannFU'],
			// med_allow_excl: true,
			medbuy_only: true,
			logoSize:{
				width:'150px',
				height:'auto'
			},
		}
	
	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------
	//		partners

	else if (['dk.test:9011', 'dk.au'].includes(location.host) || location.pathname.toLowerCase().startsWith('/dkws') || get_params_init?.get("phoid")=='cqWhpAM')
		extra = { 
			name: 'Kisla Care',
			app: 'iscr',
			mode: 'dK',
			headerLogo: '/images/logos/icon-green.png',
			sidemenuLogo: '/images/logos/icon-green.png',
			css: ["/icann/fonts.css", "https://use.typekit.net/nxz6kzj.css"],
			// incl_med_cat: ['DocConsICann', 'DocConsICannFU'],
			// med_allow_excl: true,
			// medbuy_only: true,
			logoSize:{
				width:'150px',
				height:'auto'
			},
		}

	//	------------------------	------------------------	------------------------

	else if ( ['cbls.test:9011', 'is-cbls.web.app', 'cbls.au', 'cbls.iscr.au', 'bls.iscr.au', 'blsclinics.com.au'].includes(location.host) || location.pathname.toLowerCase().startsWith('/cblsws') || get_params_init?.get("poid")=='szgbqzk' || get_params_init?.get('pnm')?.toLowerCase()=='cbls' )
		extra = { 
			name: 'BLS Clinics',
			app: 'icann',
			mode: 'cbls',
			partn_oid: 'szgbqzk',
			incl_med_cat: ['DocConsICann-cbls', 'DocConsICannFU-cbls'],
			medbuy_only: true,
			// med_allow_excl: true,

			headerLogo:'https://storage.googleapis.com/instant-med-public/BLSClinics/BLS-Clinics-logo.png',
			sidemenuLogo:'https://storage.googleapis.com/instant-med-public/BLSClinics/BLS-Clinics-logo-white.png',
			css:["/icann/cbls/fonts.css", "https://use.typekit.net/nxz6kzj.css"],
			logoSize:{
				width:'150px',
				height:'auto'
			},

			// headerLogo: 'https://cdn.shopify.com/s/files/1/0599/1602/5913/files/Untitled-1_ed027b37-418c-4453-8cd5-069d22e0862d.png?v=1668037300&width=500',
			// sidemenuLogo: 'https://cdn.shopify.com/s/files/1/0599/1602/5913/files/Untitled-1_ed027b37-418c-4453-8cd5-069d22e0862d.png?v=1668037300&width=500',
		}

	//	------------------------	------------------------	------------------------

	else if ( ['cmg.test:9011','is-cmg.web.app', 'cmg.au', 'cmg.iscr.au', 'hh.iscr.au', 'haiku.health'].includes(location.host) || location.pathname.toLowerCase().startsWith('/cmgws') || get_params_init?.get("poid")=='TBeDl4M' || get_params_init?.get('pnm')?.toLowerCase()=='cmg' )
		extra = { 
			name: 'Haiku Health',
			app: 'icann',
			mode: 'cmg',
			partn_oid: 'TBeDl4M',
			headerLogo: 'https://storage.googleapis.com/instant-med-public/Haiku.Health/haiku-logo-black.svg',
			sidemenuLogo: 'https://storage.googleapis.com/instant-med-public/Haiku.Health/haiku-logo-white.svg',
			css: ["/icann/cmg/fonts.css", "https://use.typekit.net/nxz6kzj.css"],
			incl_med_cat: ['DocConsICann-cmg', 'DocConsICannFU-cmg'],
			// med_allow_excl: true,
			// medbuy_only: true,
			logoSize:{
				width:'150px',
				height:'auto'
			},
		}

	else if (['iscr2.test:9011', 'd2.iscr.com.au'].includes(location.host) || location.pathname.toLowerCase().startsWith('/is2theme') || sticky_runt==='iscr2')
		extra = {
			theme:'iscr2',
			headerLogo:'/images/logos/instant-scripts-logo-IS2-white.svg',
			login_type: 'page',
		}

	//	------------------------	------------------------	------------------------

	else if ( ['prh.test:9011'].includes(location.host) || location.pathname.toLowerCase().startsWith('/prhws') || get_params_init?.get("poid")=='G3G1ZVQ' || get_params_init?.get('pnm')?.toLowerCase()=='prh' )
		extra = { 
			partn_oid: 'G3G1ZVQ',
			incl_med_cat: ['-NQi3kEk3nXo4hjWK3-V','-NRecaAbZYaXNw3N0EKb'],
		}

	//	------------------------	------------------------	------------------------

	else if (sticky_runt !== 'iscr') {
		extra = {
			theme: 'iscr2',
			headerLogo: '/images/logos/instant-scripts-logo-IS2-white.svg',
		}
	}

	//	------------------------	------------------------	------------------------

	get_params_init?.get("phoid")
		&& (base.partn_pharm_oid = get_params_init.get("phoid"))

	return {...dflt, ...base, ...extra, get_params_init, init_params_obj:Object.fromEntries(get_params_init), ref_init}
}

import user_model from "@/models/user_model";
import { cls } from "@/views/NUI/utils";
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { TextField } from '../../../views/UIelems/v2/Form/TextField'
import { validateAccountDetails } from 'helpers/signupValidation';
import { NotificationBar } from "@/views/UIelems/v2/notificationBar/NotificationBar";
import { ConcessionsDrawer } from "./ConcessionsDrawer";
import MaskedInput from "react-text-mask";

const UserSignUpAccountDetails = ({ onSubmit, loading, authError }) => {
    const [state, dispatch] = useReducer(reducer, initialState());
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const scrollToError = (errors) => {
        const firstErrorField = Object.keys(errors)[0];
        const errorElement = document.querySelector(`[name="${firstErrorField}"]`);

        if (errorElement) {
            const offset = 100;
            const elementPosition = errorElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });

            errorElement.focus();
        }
    };

    const clearError = (fieldName) => {
        setErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[fieldName];
            return newErrors;
        });
    };

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const formErrors = validateAccountDetails(state);
        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            if (typeof onSubmit === 'function') {
                onSubmit(state);
            }
        } else {
            scrollToError(formErrors);
        }
    };

    return (
        <Form
            className="w-full flex flex-col gap-2 md:gap-4 lg:mt-9 lg:mb-[116px]"
            onSubmit={handleOnSubmit}
            noValidate
        >
            <div className="w-full max-w-screen-md mx-auto sm:px-4">
                <div className="bg-white pt-10 pb-6 px-6 max-w-screen-md lg:rounded">
                    <AccountDetails state={state} dispatch={dispatch} errors={errors} clearError={clearError} />
                </div>
                <div className="bg-white pb-4 px-6 mt-2 pt-8 max-w-screen-md">
                    <Concessions state={state} dispatch={dispatch} errors={errors} clearError={clearError} />
                </div>
                {authError && (
                    <div className="mb-2">
                        <NotificationBar type="negative" text={authError.message} className={"text-center"} />
                    </div>
                )}
                <div className="bg-white py-8 px-6 max-w-screen-md">
                    <Button primary fluid type="submit" loading={loading} disabled={loading}>
                        Save
                    </Button>
                </div>
            </div>
        </Form>
    )
};

export default UserSignUpAccountDetails;

const atsi = user_model.PROF_FIELDS_MY.find(f => f.name === 'atsi');

function AccountDetails({ state, dispatch, errors, clearError }) {
    return (
        <div className="flex flex-col gap-3">
            <h3 className="m-0 text-lg font-bold pb-3">Personal info</h3>
            <Form.Field>
                <RadioGroup
                    label="Sex assigned at birth:"
                    options={['Male', 'Female']}
                    value={state.sex}
                    onChange={value => dispatch({ type: 'SET_SEX', payload: value })}
                    error={errors.sex}
                />
            </Form.Field>
            <Form.Field>
                <RadioGroup
                    label="Are you Aboriginal or Torres Strait Islander?"
                    options={atsi.options}
                    value={state.indigenous}
                    onChange={value => dispatch({ type: 'SET_INDIGENOUS', payload: value })}
                />
            </Form.Field>
            <Form.Input
                label="Date of birth"
                fluid
                error={errors.dateOfBirth}
                >
                <MaskedInput
                    mask={[ /\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/, /\d/, /\d/, ]}
                    placeholder={'DD/MM/YYYY'}
                    onChange={e => dispatch({ type: 'SET_DOB', payload: e.target.value?.split('/').reverse().join('-')||'' })}
                    value={state?.dateOfBirth && state.dateOfBirth.split('-').reverse().join('/')}
                    name="dateOfBirth"
                    type = 'text'
                />
            </Form.Input>
            <Form.Field>
                <TextField
                    label="Mobile Phone"
                    name="mobilePhone"
                    type="tel"
                    onChange={e => dispatch({ type: 'SET_MOBILE', payload: e.target.value })}
                    value={state.mobilePhone}
                    fluid
                    error={errors.mobilePhone}
                />
            </Form.Field>
        </div>
    )
}

function Concessions({ state, dispatch }) {
    const [showConcession, setShowConcession] = useState(null);
    const [showDVA, setShowDVA] = useState(null);

    const cFields = user_model.PROF_FIELDS_IS2.filter((f) => ['has_conc_card', 'conc_card', 'has_dva_card', 'dva'].includes(f?.name))
    const fields = cFields.reduce((a,fl)=>{ if (fl.name) a[fl.name] = fl; return a },{})

    return (
        <div className="flex flex-col gap-3">
            <h3 className="m-0 !mb-5 text-lg font-bold">Concessions</h3>
            {Object.values(fields).map(f => <>
                {f.name === "has_conc_card" && <ConcessionsDrawer
                                                validate={val => {return val?.conc_card && (fields.conc_card.pattern && !(new RegExp(fields.conc_card.pattern)).test(val.conc_card)) ? {'conc_card': 'Field is missing or incorrect'} : null}}
                                                fl={fields.has_conc_card}
                                                fields={[fields.conc_card]}
                                                values={{'has_conc_card': showConcession, 'conc_card': state.concessionCard}} 
                                                onChange={(f) => f?.name == "has_conc_card" ?  setShowConcession(f.value) : dispatch({ type: "SET_CONCESSION", payload: f['conc_card']})
                                                }
                                            />
                || f.name === "has_dva_card" && <ConcessionsDrawer 
                                                validate={val => validateAccountDetails({'dvaNumber': val})} 
                                                fl={fields.has_dva_card} 
                                                fields={[{...fields.dva, name: "number", label: "Card number", placeholder: fields.dva.dva_number_placeholder, maxLength:9 }, {name:'colour', label: "Card colour", type:"select", options: fields.dva.dva_colour_options, placeholder:''}]}
                                                values={{'has_dva_card': showDVA, 'number': state.dvaNumber.number, 'colour': state.dvaNumber.colour }} 
                                                onChange={(f) => {f?.name != "has_dva_card" ?  dispatch({ type: 'SET_DVA', payload: f }) : setShowDVA(f?.value)}}
                                                />
                }
            </>)}
         </div>
    )
}

function initialState() {
    return {
        dateOfBirth: '',
        mobilePhone: '',
        sex: '',
        indigenous: null,
        concessionCard: '',
        dvaNumber: {
            number: '',
            colour: ''
        },
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'SET_DOB':
            return { ...state, dateOfBirth: action.payload };
        case 'SET_MOBILE':
            return { ...state, mobilePhone: action.payload };
        case 'SET_SEX':
            return { ...state, sex: action.payload };
        case 'SET_INDIGENOUS':
            return { ...state, indigenous: action.payload };
        case 'SET_CONCESSION':
            return { ...state, concessionCard: action.payload };
        case 'SET_DVA':
            return { ...state, dvaNumber: action.payload };
        default:
            return state;
    }
}

export function RadioGroup({ label, options, value, onChange, error }) {
    return (
        <div>
            <label className="block !mb-5" id={`${label}-label`}>{label}</label>
            <div
                className={cls("grid gap-2", options?.length > 2 ? "grid-cols-1 md:grid-cols-2" : "grid-cols-2")}
                role="radiogroup"
                aria-labelledby={`${label}-label`}
            >
                {options.map(option => (
                    typeof option === 'object' ?
                    <button
                        key={option.key}
                        type="button"
                        className={`flex-1 p-2 border rounded-lg text-base ${
                            value === option.value ? 'bg-site-brand text-white' : 'bg-white'
                        }`}
                        onClick={() => onChange(option.value)}
                        aria-checked={value === option.value}
                        role="radio"
                    >
                        {option.text}
                    </button>
                    :
                    <button
                        key={option}
                        type="button"
                        className={`flex-1 p-2 border rounded-lg text-base ${value === option.toLowerCase() ? 'bg-site-brand text-white' : 'bg-white'
                            }`}
                        onClick={() => onChange(option.toLowerCase())}
                        aria-checked={value === option.toLowerCase()}
                        role="radio"
                    >
                        {option}
                    </button>
                ))}
            </div>
            {error && <div className="text-red-600 text-sm">{error}</div>}
        </div>
    );
}